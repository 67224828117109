//screens

@import "artists";
@import "common";
@import "event-page";
@import "event-map-page";
@import "event-schedule-page";
@import "notifications-center-page";

//components

@import "uniqueInput";
@import "./component/NavigateButton";

@font-face {
  font-family: 'Transat';
  font-style: normal;
  font-weight: 400;
  src: local('Transat'), url('../../../public/fonts/transat_standard.ttf') format('truetype');
}

@font-face {
  font-family: 'Transat';
  font-style: normal;
  font-weight: 700;
  src: local('Transat'), url('../../../public/fonts/transat_bold.ttf') format('truetype');
}

.modal-backdrop {
  z-index: 1;
}
