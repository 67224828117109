.add_btn {
  max-width: 64px;
  margin: 12px 0 12px 0;
}

.add_btn__wrapper {
  justify-content: end;
}

.td__btn {
  display: flex;
  justify-content: space-evenly;
}
