.add_btn {
  max-width: 64px;
  margin: 12px 0 12px 0;
}

.add_btn__wrapper {
  justify-content: end;
}

.td__btn {
  display: flex;
  justify-content: space-evenly;
}

* {
  padding: 0;
  margin: 0;
}

.navbar {
  align-items: center;
  padding: 16px 86px 16px 24px;
  background: #ffffff;
  box-shadow: 1px 2px 11px rgba(148, 148, 148, 0.25);
}
.navbar .container {
  justify-content: right;
}
@media (max-width: 991px) {
  .navbar .container {
    justify-content: space-between;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  /* z-index: 100; */
  padding: 24px 0 0 48px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: inherit;
  background: #ffffff;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.sidebar__nav-item__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  position: absolute;
  /* width: 65px;
  height: 176px;
  left: 75px;
  top: 240px;
   */
}

.sidebar__nav-item__container > .nav-item {
  margin-top: 16px;
}

.sidebar__link {
  text-decoration: none;
  padding: 0 16px;
  position: static;
  width: 40px;
  height: 20px;
  left: 0;
  top: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 32px 0;
}

.sidebar__link:hover {
  font-weight: bold;
  color: #000;
}

.sidebar__link__active {
  font-weight: bold;
  position: relative;
  padding-left: 40px;
}
.sidebar__link__active:before {
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  height: 2px;
  width: 18px;
  background: black;
  position: absolute;
}

.sidebar__logo {
  max-height: 60px;
  margin: 0 24px 48px;
}

.navbar__search_form {
  width: 75%;
}

.navbar__search_form__input {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 8px;
}

.navbar__avatar {
  height: 40px;
  width: 40px;
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  position: relative;
}

.navbar__avatar__status {
  bottom: 0;
  right: 0;
  margin: -4px;
  width: 12px;
  height: 12px;
  position: absolute;
  align-items: baseline;
  justify-content: end;
  background: #4ad15f;
  border: 2px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 100px;
}

.navbar__avatar__status-ofline {
  background-color: #c2c2c2;
}

.event-page__container {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}
@media (min-width: 1199px) {
  .event-page__container {
    padding-right: 64px;
  }
}
.event-page__container .event-page__breadcrumb-container {
  margin-top: 24px;
}
.event-page__container .event-page__breadcrumb-container .event-page__breadcrumb-item_1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #929292;
}
.event-page__container .event-page__main .success-message {
  color: green;
  margin-top: 6px;
  font-size: 14px;
  position: absolute;
  bottom: -32px;
}
.event-page__container .event-page__main .error-message {
  color: red;
  margin-top: 6px;
  font-size: 14px;
  position: absolute;
  bottom: -32px;
}
.event-page__container .event-page__main-block-button_save {
  margin-top: 20px;
  align-items: center;
  padding: 8px 16px;
  color: white;
  background: #00ADEF;
  border: 1px solid #00ADEF;
  font-weight: 700;
  border-radius: 10px;
}
.event-page__container .event-page__main-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  color: #000;
}
.event-page__container .event-page__main-block {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}
.event-page__container .event-page__main-block_message {
  position: relative;
}
.event-page__container .event-page__main-block_button-save {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00ADEF;
  box-sizing: border-box;
  border-radius: 8px;
  color: #00ADEF;
  font-family: transat_bold, serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
  margin-top: 45px;
}
.event-page__container .event-page__main-block_button-save:hover {
  background: #00ADEF;
  color: #fff;
  cursor: pointer;
}
.event-page__container .event-page__main-block .event-page__main_input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.event-page__container .event-page__main-block .event-page__main_input-container .event-page__main_input-container-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}
.event-page__container .event-page__main-block-upload {
  margin-top: 16px;
  position: relative;
}
.event-page__container .event-page__main-block-upload .event-page__block-upload {
  display: flex;
  gap: 50px;
  margin-bottom: 30px;
}
.event-page__container .event-page__main-block-upload .event-page__block-upload_img {
  width: 502px;
  height: 252px;
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
}
.event-page__container .event-page__main-block-upload_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 8px;
}
.event-page__container .event-page__main-block-upload_container {
  display: inline-block;
  position: relative;
}
.event-page__container .event-page__main-block-upload .block-upload {
  position: relative;
  top: 0;
  width: 500px;
  height: 250px;
  border: 2px dashed #009ef7;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.event-page__container .event-page__main-block-upload .block-upload input {
  z-index: 10;
}
.event-page__container .event-page__main-block-upload .block-upload__image {
  width: 24px;
  height: 24px;
  background: url("../image/input-uploader-logo.svg");
  background-size: contain;
}
.event-page__container .event-page__main-block-upload .block-upload__container-text_part-1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  color: #009ef7;
}
.event-page__container .event-page__main-block-upload .block-upload__container-text_part-2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color: #9899ac;
}
.event-page__container .event-page__main-block-navigate_button {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

@font-face {
  font-family: "transat_bold";
  src: url("../../../public/fonts/transat_bold.ttf");
}
@font-face {
  font-family: "transat_standard";
  src: url("../../../public/fonts/transat_standard.ttf");
}
.EventMap-container {
  height: 730px;
  margin-left: 24px;
  margin-right: 24px;
}
.EventMap-container .EventMap-breadcrumbs__container {
  margin-top: 44px;
  margin-bottom: 24px;
  display: flex;
  gap: 11px;
}
.EventMap-container .EventMap-breadcrumbs__container-item_1 {
  display: flex;
  align-items: center;
  color: #929292;
  gap: 11px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
}
.EventMap-container .EventMap-breadcrumbs__container-item_2 {
  display: flex;
  align-items: center;
  color: #929292;
  gap: 11px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
}
.EventMap-container .EventMap-breadcrumbs__container-arrow {
  width: 10px;
  height: 10px;
  background: url("../image/breadcrumb-arrow-ligi-right.svg");
  background-size: contain;
}
.EventMap-container .EventMap-main {
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 24px;
}
.EventMap-container .EventMap-main__title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 19px;
  border-bottom: 2px solid #EFF2F5;
}
.EventMap-container .EventMap-main__content {
  margin-top: 19px;
}
.EventMap-container .EventMap-main__content .image-loader_container {
  position: absolute;
  top: 50px;
  max-width: 266px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.EventMap-container .EventMap-main__content-file-uploader {
  position: relative;
}
.EventMap-container .EventMap-main__content-file-uploader .block-upload {
  position: relative;
  top: 0;
  width: 100%;
  max-width: 600px;
  height: 300px;
  border: 2px dashed #009EF7;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.EventMap-container .EventMap-main__content-file-uploader .block-upload__image {
  width: 24px;
  height: 24px;
  background: url("../image/input-uploader-logo.svg");
  background-size: contain;
}
.EventMap-container .EventMap-main__content-file-uploader .block-upload__container-text_part-1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  color: #009EF7;
}
.EventMap-container .EventMap-main__content-file-uploader .block-upload__container-text_part-2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color: #9899AC;
}
.EventMap-container .EventMap-main__content .EventMap-success {
  color: green;
  margin-top: 10px;
}
.EventMap-container .EventMap-main__content .EventMap-error {
  color: red;
  margin-top: 10px;
}
.EventMap-container .EventMap-main__content .sc-bdvvtL {
  min-width: unset;
  max-width: unset;
  opacity: 0;
  height: 69px;
  z-index: 2;
}
.EventMap-container .EventMap-main__content .EventMap-img__data {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.EventMap-container .EventMap-main__content .EventMap-img__data_item-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.EventMap-container .EventMap-main__content .EventMap-img__data_item-2 {
  display: flex;
  align-items: center;
  gap: 24px;
}
.EventMap-container .EventMap-main__content .EventMap-img__data-jpg_logo {
  width: 22px;
  height: 22px;
  background: url("../image/event-map-jpg-file-logo.svg");
  background-size: contain;
}
.EventMap-container .EventMap-main__content .EventMap-img__data-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #3F4254;
}
.EventMap-container .EventMap-main__content .EventMap-img__data-size {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  color: #B5B5C3;
}
.EventMap-container .EventMap-main__content .EventMap-img__data-remove_logo {
  width: 12px;
  height: 13px;
  background: url("../image/event-map-red-trash-logo.svg");
  background-size: contain;
}
.EventMap-container .EventMap-main__content .EventMap-img__data-uploaded_by-admin {
  width: 602px;
  position: absolute;
  left: -3px;
  top: -3px;
  height: 302px;
  object-fit: cover;
  border-radius: 12px;
}

.EventSchedule-container {
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 24px;
}
.EventSchedule-container__modal .error-line {
  color: red;
  padding-left: 1.2rem;
  margin-bottom: 10px;
}
.EventSchedule-container__modal .modal__title-time {
  margin-bottom: 10px;
}
.EventSchedule-container__modal .modal__title-artist {
  margin-bottom: 10px;
  margin-top: 10px;
}
.EventSchedule-container__modal .modal__title-scene {
  margin-bottom: 10px;
  margin-top: 10px;
}
.EventSchedule-container__modal .unactive button#dropdown-item-button {
  box-shadow: none;
  background: white;
  color: #929292;
  border-color: #EBEBEB;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 8px;
}
.EventSchedule-container__modal .active button#dropdown-item-button {
  box-shadow: none;
  background: white;
  color: black;
  border-color: #EBEBEB;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 8px;
}
.EventSchedule-container__modal_dropdown .dropdown-menu {
  width: 100%;
}
.EventSchedule-container__modal .modal-footer {
  display: flex;
  justify-content: space-between;
}
.EventSchedule-container__modal .modal-footer .button-cancel {
  color: #00ADEF;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.EventSchedule-container__breadcrumbs {
  display: flex;
  gap: 11px;
}
.EventSchedule-container__breadcrumbs .breadcrumbs-item {
  display: flex;
  align-items: center;
  gap: 11px;
  font-size: 12px;
  font-weight: 400;
  color: #929292;
  cursor: pointer;
  line-height: 16px;
  letter-spacing: 0;
}
.EventSchedule-container__breadcrumbs .breadcrumbs-item__arrow {
  width: 16px;
  height: 16px;
  background: url("../image/breadcrumb-arrow-ligi-right.svg");
  background-size: contain;
}
.EventSchedule-container .EventSchedule-main {
  margin-top: 24px;
  padding: 24px;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 12px;
}
.EventSchedule-container .EventSchedule-main__title {
  font-size: 32px;
  font-weight: 700;
  border-bottom: 2px solid #EFF2F5;
  padding-bottom: 19px;
  line-height: 40px;
  letter-spacing: 0;
}
.EventSchedule-container .EventSchedule-main__table {
  margin-top: 35px;
}
.EventSchedule-container .EventSchedule-main__table-header {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  font-size: 14px;
  font-weight: 700;
}
.EventSchedule-container .EventSchedule-main__table-header .table-header_column-time {
  width: 100%;
  max-width: 240px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.EventSchedule-container .EventSchedule-main__table-header .table-header_column-artist {
  width: 100%;
  max-width: 240px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.EventSchedule-container .EventSchedule-main__table-header .table-header_column-scene {
  width: 100%;
  max-width: 240px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.EventSchedule-container .EventSchedule-main__table-header .table-item__button {
  min-width: 170px;
}
.EventSchedule-container .EventSchedule-main__table-body .schedule-data__is-empting {
  height: 120px;
  margin-top: 20px;
  max-width: 870px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
}
.EventSchedule-container .EventSchedule-main__table-body .loader-center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  padding: 5px 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__time {
  width: 100%;
  max-width: 240px;
  word-break: break-all;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__artist {
  width: 100%;
  max-width: 240px;
  word-break: break-all;
  font-size: 14px;
  line-height: 24px;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__scene {
  width: 100%;
  max-width: 240px;
  word-break: break-all;
  font-size: 14px;
  line-height: 24px;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__button {
  min-width: 170px;
  display: flex;
  gap: 20px;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__button_edit {
  width: 44px;
  height: 32px;
  border: 1.5px solid #00ADEF;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: #00ADEF;
  cursor: pointer;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__button_edit:hover {
  background: #00ADEF;
  color: white;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__button_remove {
  color: #D33030;
  width: 57px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  border-radius: 8px;
}
.EventSchedule-container .EventSchedule-main__table-body .table-item__button_remove:hover {
  border: 1px solid #D33030;
}
.EventSchedule-container .EventSchedule-main__button-add {
  height: 48px;
  max-width: 875px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00ADEF;
  box-sizing: border-box;
  border-radius: 8px;
  color: #00ADEF;
  font-family: transat_bold, serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  margin-top: 35px;
}
.EventSchedule-container .EventSchedule-main__button-add:hover {
  cursor: pointer;
  background: #00ADEF;
  color: white;
}

.NotificationsCenter-container {
  margin-left: 24px;
  margin-top: 24px;
  margin-right: 64px;
}
.NotificationsCenter-container .NotificationsCenter-breadcrumbs_item-1 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #929292;
}
.NotificationsCenter-container .NotificationsCenter-main {
  margin-top: 24px;
  margin-bottom: 24px;
}
.NotificationsCenter-container .NotificationsCenter-main .center-loader {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NotificationsCenter-container .NotificationsCenter-main__empty-block {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
}
.NotificationsCenter-container .NotificationsCenter-main__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
}
.NotificationsCenter-container .NotificationsCenter-main__searchbar {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications .item-body .item-body__delete {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0;
  color: #D33030;
  height: 42px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications .item-body .item-body__delete:hover {
  border: 1px solid #D33030;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications-time_based .time_based__title {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 32px;
  margin-bottom: 16px;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications-time_based .time_based__container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications-push_based .push_based__title {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 32px;
  margin-bottom: 16px;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications-push_based .push_based__container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications-sent_notifications .sent_notifications__title {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 32px;
  margin-bottom: 16px;
}
.NotificationsCenter-container .NotificationsCenter-main__notifications-sent_notifications .sent_notifications__container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.UniqueInput {
  position: relative;
  width: auto;
}
.UniqueInput .UniqueInput-input {
  width: 100%;
  height: 48px;
  border: 1px solid #EBEBEB;
  outline: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
}
.UniqueInput .UniqueInput-input_with-logo {
  width: 100%;
  height: 48px;
  border: 1px solid #EBEBEB;
  outline: none;
  border-radius: 8px;
  padding: 12px 43px 12px 16px;
  font-size: 16px;
  font-weight: 400;
}
.UniqueInput .UniqueInput-image_location {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url("../image/input-logo-maps.svg");
  background-size: contain;
}
.UniqueInput .UniqueInput-image_calendar {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url("../image/input-logo-calendar.svg");
  background-size: contain;
}

.NavigateButton {
  width: 100%;
  height: 70px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  cursor: pointer;
}

.NavigateButton-image {
  position: absolute;
  right: 46px;
}

@font-face {
  font-family: "Transat";
  font-style: normal;
  font-weight: 400;
  src: local("Transat"), url("../../../public/fonts/transat_standard.ttf") format("truetype");
}
@font-face {
  font-family: "Transat";
  font-style: normal;
  font-weight: 700;
  src: local("Transat"), url("../../../public/fonts/transat_bold.ttf") format("truetype");
}
.modal-backdrop {
  z-index: 1;
}

