@font-face {
  font-family: 'transat_bold';
  src: url('../../../public/fonts/transat_bold.ttf');
}

@font-face {
  font-family: 'transat_standard';
  src: url('../../../public/fonts/transat_standard.ttf');
}

.EventMap {
  &-container {
    //max-width: 1100px;
    height: 730px;
    margin-left: 24px;
    margin-right: 24px;

    .EventMap-breadcrumbs__container {
      margin-top: 44px;
      margin-bottom: 24px;
      display: flex;
      gap: 11px;

      &-item_1 {
        display: flex;
        align-items: center;
        color: #929292;
        gap: 11px;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;

      }
      &-item_2 {
        display: flex;
        align-items: center;
        color: #929292;
        gap: 11px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
      }
      &-arrow {
        width: 10px;
        height: 10px;
        background: url("../image/breadcrumb-arrow-ligi-right.svg");
        background-size: contain;
      }
    }
    .EventMap-main {
      border: 1px solid #E1E1E1;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 24px;
      &__title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0;
        text-align: left;
        padding-bottom: 19px;
        border-bottom: 2px solid #EFF2F5;
      }
      &__content {
        margin-top: 19px;
        .image-loader_container {
          position: absolute;
          top: 50px;
          max-width: 266px;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
        }
        &-file-uploader {
          position: relative;
          .block-upload {
            position: relative;
            top: 0;
            width: 100%;
            max-width: 600px;
            height: 300px;
            border: 2px dashed #009EF7;
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            &__image {
              width: 24px;
              height: 24px;
              background: url("../image/input-uploader-logo.svg");
              background-size: contain;
            }
            &__container-text {
              &_part-1 {
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0;
                color: #009EF7;
              }
              &_part-2 {
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
                color: #9899AC;
              }
            }
          }
        }
        .EventMap-success {
          color: green;
          margin-top: 10px;
        }
        .EventMap-error {
          color: red;
          margin-top: 10px;
        }
        .sc-bdvvtL {
          min-width: unset;
          max-width: unset;
          opacity: 0;
          height: 69px;
          z-index: 2;
        }
        .EventMap-img__data {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          &_item-1 {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          &_item-2 {
            display: flex;
            align-items: center;
            gap: 24px;
          }
          &-jpg_logo {
            width: 22px;
            height: 22px;
            background: url("../image/event-map-jpg-file-logo.svg");
            background-size: contain;
          }
          &-name {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.015em;
            text-align: center;
            color: #3F4254;
          }
          &-size {
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0;
            text-align: center;
            color: #B5B5C3;
          }
          &-remove_logo {
            width: 12px;
            height: 13px;
            background: url("../image/event-map-red-trash-logo.svg");
            background-size: contain;
          }
        }
        .EventMap-img__data-uploaded_by-admin {
          width: 602px;
          position: absolute;
          left: -3px;
          top: -3px;
          height: 302px;
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }
  }
}
