.UniqueInput {
  position: relative;
  width: auto;
  .UniqueInput-input {
    width: 100%;
    height: 48px;
    border: 1px solid #EBEBEB;
    outline: none;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 400;
  }
  .UniqueInput-input_with-logo {
    width: 100%;
    height: 48px;
    border: 1px solid #EBEBEB;
    outline: none;
    border-radius: 8px;
    padding: 12px 43px 12px 16px;
    font-size: 16px;
    font-weight: 400;
  }
  .UniqueInput-image_location {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url("../image/input-logo-maps.svg");
    background-size: contain;
  }
  .UniqueInput-image_calendar {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url("../image/input-logo-calendar.svg");
    background-size: contain;
  }
}
