.NavigateButton {
  width: 100%;
  height: 70px;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  cursor: pointer;
}

.NavigateButton-image {
  position: absolute;
  right: 46px;
}
