.NotificationsCenter {
  &-container {
    margin-left: 24px;
    margin-top: 24px;
    margin-right: 64px;
    //max-width: 1100px;
    .NotificationsCenter-breadcrumbs {
      &_item-1 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        color: #929292;
      }
    }
    .NotificationsCenter-main {
      margin-top: 24px;
      margin-bottom: 24px;
      .center-loader {
        height: 120px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__empty-block {
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 30px;
        font-weight: bold;
      }
      &__title {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0;
      }
      &__searchbar {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
      }
      &__notifications {
        .item-body {
          .item-body__delete {
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0;
            color: #D33030;
            height: 42px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 8px;
            &:hover {
              border: 1px solid #D33030;
            }
          }
        }
        &-time_based {
          .time_based__title {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;

            margin-top: 32px;
            margin-bottom: 16px;
          }
          .time_based__container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
          }
        }
        &-push_based {
          .push_based__title {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;

            margin-top: 32px;
            margin-bottom: 16px;
          }
          .push_based__container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
          }
        }
        &-sent_notifications {
          .sent_notifications__title {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;

            margin-top: 32px;
            margin-bottom: 16px;
          }
          .sent_notifications__container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
          }
        }
      }
    }
  }
}
