$width-table-item: 240px;

.EventSchedule {
  &-container {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 24px;
    &__modal {
      .error-line {
        color: red;
        padding-left: 1.2rem;
        margin-bottom: 10px;
      }
      .modal__title-time {
        margin-bottom: 10px;
      }
      .modal__title-artist {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .modal__title-scene {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .unactive {
        button#dropdown-item-button {
          box-shadow: none;
          background: white;
          color: #929292;
          border-color: #EBEBEB;
          width: 100%;

          font-size: 16px;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          border-radius: 8px;
        }
      }
      .active {
        button#dropdown-item-button {
          box-shadow: none;
          background: white;
          color: black;
          border-color: #EBEBEB;
          width: 100%;

          font-size: 16px;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 48px;
          border-radius: 8px;
        }
      }
      &_dropdown {
        .dropdown-menu {
          width: 100%;
        }
      }
      .modal-footer {
        display: flex;
        justify-content: space-between;
        .button-cancel {
          color: #00ADEF;
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    &__breadcrumbs {
      display: flex;
      gap: 11px;
      .breadcrumbs-item {
        display: flex;
        align-items: center;
        gap: 11px;
        font-size: 12px;
        font-weight: 400;
        color: #929292;
        cursor: pointer;
        line-height: 16px;
        letter-spacing: 0;

        &__arrow {
          width: 16px;
          height: 16px;
          background: url("../image/breadcrumb-arrow-ligi-right.svg");
          background-size: contain;
        }
      }
    }
    .EventSchedule-main {
      margin-top: 24px;
      padding: 24px;
      border: 1px solid #E1E1E1;
      box-sizing: border-box;
      border-radius: 12px;
      &__title {
        font-size: 32px;
        font-weight: 700;
        border-bottom: 2px solid #EFF2F5;
        padding-bottom: 19px;

        line-height: 40px;
        letter-spacing: 0;
      }
      &__table {
        margin-top: 35px;
        &-header {
          display: flex;
          justify-content: space-between;
          gap: 25px;

          font-size: 14px;
          font-weight: 700;

          .table-header_column-time {
            width: 100%;
            max-width: $width-table-item;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 12px;
          }
          .table-header_column-artist {
            width: 100%;
            max-width: $width-table-item;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 12px;
          }
          .table-header_column-scene {
            width: 100%;
            max-width: $width-table-item;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 12px;
          }
          .table-item__button {
            min-width: $width-table-item - 70px;
          }
        }
        &-body {
          .schedule-data__is-empting {
            height: 120px;
            margin-top: 20px;
            max-width: 870px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
          }
          .loader-center {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .table-item {
            display: flex;
            justify-content: space-between;
            gap: 25px;
            align-items: center;
            padding: 5px 0;
            box-sizing: border-box;

            font-size: 14px;
            font-weight: 400;
            margin-top: 12px;

            &__time {
              width: 100%;
              max-width: $width-table-item;
              word-break: break-all;
            }
            &__artist {
              width: 100%;
              max-width: $width-table-item;
              word-break: break-all;

              font-size: 14px;
              line-height: 24px;
            }
            &__scene {
              width: 100%;
              max-width: $width-table-item;
              word-break: break-all;

              font-size: 14px;
              line-height: 24px;
            }
            &__button {
              min-width: $width-table-item - 70px;
              display: flex;
              gap: 20px;
              &_edit {
                width: 44px;
                height: 32px;
                border: 1.5px solid #00ADEF;
                box-sizing: border-box;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0;
                color: #00ADEF;
                cursor: pointer;
                &:hover {
                  background: #00ADEF;
                  color: white;
                }
              }
              &_remove {
                color: #D33030;
                width: 57px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                box-sizing: border-box;

                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0;
                border-radius: 8px;

                &:hover {
                  border: 1px solid #D33030;
                }
              }
            }
          }
        }
      }
      &__button-add {
        height: 48px;
        max-width: 875px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #00ADEF;
        box-sizing: border-box;
        border-radius: 8px;
        color: #00ADEF;

        font-family: transat_bold, serif;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0;


        margin-top: 35px;
        &:hover {
          cursor: pointer;
          background: #00ADEF;
          color: white;
        }
      }
    }
  }
}
