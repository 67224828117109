.event-page {
  &__container {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    @media (min-width: 1199px) {
      padding-right: 64px;
    }
    .event-page__breadcrumb-container {
      margin-top: 24px;
      .event-page__breadcrumb-item_1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        color: #929292;
      }
    }
    .event-page__main {
      .success-message {
        color: green;
        margin-top: 6px;
        font-size: 14px;
        position: absolute;
        bottom: -32px;
      }
      .error-message {
        color: red;
        margin-top: 6px;
        font-size: 14px;
        position: absolute;
        bottom: -32px;
      }
      &-block-button_save {
        margin-top: 20px;
        align-items: center;
        padding: 8px 16px;
        color: white;
        background: #00ADEF;
        border: 1px solid #00ADEF;
        font-weight: 700;
        border-radius: 10px;
      }
      &-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0;
        color: #000;
      }
      &-block {
        display: flex;
        //flex-wrap: wrap;
        gap: 16px;
        margin-top: 16px;
        &_message {
          position:  relative;
        }
        &_button-save {
          height: 48px;
          //max-width: 1045px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #00ADEF;
          box-sizing: border-box;
          border-radius: 8px;
          color: #00ADEF;
          font-family: transat_bold, serif;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0;
          margin-top: 45px;
          &:hover {
            background: #00ADEF;
            color: #fff;
            cursor: pointer;
          }
        }
        .event-page__main_input-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          .event-page__main_input-container-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
          }
        }
      }
      &-block-upload {
        margin-top: 16px;
        position: relative;
        .event-page__block-upload {
          display: flex;
          gap: 50px;
          margin-bottom: 30px;
          &_img {
            width: 502px;
            height: 252px;
            object-fit: cover;
            border-radius: 10px;
            position: absolute;
          }
        }
        &_title {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          margin-bottom: 8px;
        }
        &_container {
          display: inline-block;
          position: relative;
        }
        .block-upload {
          position: relative;
          top: 0;
          width: 500px;
          height: 250px;
          border: 2px dashed #009ef7;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          input {
            z-index: 10;
          }
          &__image {
            width: 24px;
            height: 24px;
            background: url('../image/input-uploader-logo.svg');
            background-size: contain;
          }
          &__container-text {
            &_part-1 {
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0;
              color: #009ef7;
            }
            &_part-2 {
              font-size: 12px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              color: #9899ac;
            }
          }
        }
      }
      &-block-navigate_button {
        display: flex;
        gap: 16px;
        margin-top: 24px;
      }
    }
  }
}
