* {
  padding: 0;
  margin: 0;
}

.navbar {
  align-items: center;
  padding: 16px 86px 16px 24px;
  background: #ffffff;
  box-shadow: 1px 2px 11px rgba(148, 148, 148, 0.25);
  .container {
    justify-content: right;
    @media (max-width: 991px) {
      justify-content: space-between;
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  /* z-index: 100; */
  padding: 24px 0 0 48px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: inherit;
  background: #ffffff;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.sidebar__nav-item__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  position: absolute;
  /* width: 65px;
height: 176px;
left: 75px;
top: 240px;
 */
}

.sidebar__nav-item__container > .nav-item {
  margin-top: 16px;
}

.sidebar__link {
  text-decoration: none;
  padding: 0 16px;
  position: static;
  width: 40px;
  height: 20px;
  left: 0;
  top: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 32px 0;
}

.sidebar__link:hover {
  font-weight: bold;
  color: #000;
}
.sidebar__link__active {
  font-weight: bold;
  position: relative;
  padding-left: 40px;
  &:before {
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    height: 2px;
    width: 18px;
    background: black;
    position: absolute;
  }
}

.sidebar__logo {
  max-height: 60px;
  margin: 0 24px 48px;
}

.navbar__search_form {
  width: 75%;
}

.navbar__search_form__input {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 8px;
}

.navbar__avatar {
  height: 40px;
  width: 40px;
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  position: relative;
}

.navbar__avatar__status {
  bottom: 0;
  right: 0;
  margin: -4px;
  width: 12px;
  height: 12px;
  position: absolute;
  align-items: baseline;
  justify-content: end;
  background: #4ad15f;
  border: 2px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 100px;
}

.navbar__avatar__status-ofline {
  background-color: #c2c2c2;
}
